$(document).ready(function() {
    $('.menu-toggle').on('click', function() {
        $(this).toggleClass('active');

        $('.main-nav > ul').slideToggle(200);
    });

    if ( $('#darth-phader').length ) {
        $('#darth-phader').darthphader({
            'animationDuration' : 500,
            'waitTime' : 5000,
            'autoPhader' : true,
            'crossfade' : true,
            'slide' : '.slide',
            'container' : '.slides',
            'accessible' : true,
            'showNav' : true,
            'navStyle' : 'buttons',
            'buttonClass' : '.button'
        });
    }

    if( $('.bradley-gallery').length > 0 ) {
        $('.bradley-gallery').productfeatureimages({
            'container': '.bradley-gallery',
            'feature': '.gallery-main-image',
            'thumbs': '.gallery-thumbs',
            'loading': '.gallery-loading'
        });
    }
});
