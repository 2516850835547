/**
 * ProductFeatureImages
 *
 * @author Phil Steer <pd@incb.us>
 * @package ProductFeatureImages
 */
!function($) {
    var ProductFeatureImages = function(elem, opts) {
        this.init('productfeatureimages', elem, opts);
    };

    ProductFeatureImages.prototype = {
        constructor: ProductFeatureImages,
        init: function(type, elem, opts) {
            this.type = type;
            this.$element = $(elem);
            this.options = this.getOptions(opts);
            this.$container = this.$element.find(this.options.container);
            this.$feature = this.$element.find(this.options.feature);
            this.$thumbs = this.$element.find(this.options.thumbs);
            this.$loading = this.$element.find(this.options.loading);
            this.setup();
        },

        getOptions: function(opts) {
            return $.extend({}, $.fn[this.type].defaults, this.$element.data(), opts);
        },

        setup: function() {
            var that = this;

            this.$thumbs.find('a').on('click', function(e) {
                e.preventDefault();

                var featureImg = $(this).attr('href');
                that.switchFeatureImg(featureImg);
            });
        },

        switchFeatureImg: function(featureImg) {
            var that = this;
            this.$loading.addClass('active');

            this.$feature.find('img').one('load', function() {
                that.$loading.removeClass('active');
            }).attr('src', featureImg);
        }
    };

    $.fn.productfeatureimages = function(option) {
        var args = Array.prototype.slice.call(arguments, 1);

        if (typeof option === 'string' && $.inArray(option, []) !== -1) {
            var data = this.data('productfeatureimages');
            return data[option].apply(data, args);
        }

        return this.each(function() {
            var $this = $(this),
               data = $this.data('productfeatureimages'),
               options = typeof option == 'object' && option;

            if (!data) {
                $this.data('productfeatureimages', data = new ProductFeatureImages(this, options));
            }

            if (typeof option == 'string') {
                data[option]();
            }
        });
    };

    $.fn.productfeatureimages.defaults = {
        'container': '.feature-images__container',
        'feature': '.feature-images__large',
        'thumbs': '.feature-images__thumbs',
        'loading': '.feature-images__loading'
    };
}(window.jQuery);
